<template>
  <v-overlay
    :value="show"
  >
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Выбор товаров</span>
        <v-btn
          color="error"
          small
          @click="onCloseClick"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
        class="my-3"
      />
      <div class="white pa-5 rounded-0">
        <v-row>
          <v-col
            cols="2"
          >
            <div class="d-flex align-center pb-3">
              <v-checkbox
                v-model="withWares"
                :disabled="!modalOptions.selectMultiple"
                color="grey darken-3"
                :label="`Товары (${selectedWares.length}/${items.length})`"
                hide-details="true"
              />
            </div>
          </v-col>
          <v-col
            cols="10"
          >
            <v-alert
              v-if="isDisabled"
              class="mb-0"
              dense
              text
              color="orange"
              type="warning"
            >
              Выберите товары
            </v-alert>
          </v-col>
        </v-row>
        <div>
          <div class="d-flex my-4">
            <v-text-field
              ref="rangeField"
              v-model="range"
              :disabled="!modalOptions.selectMultiple"
              hide-details="auto"
              background-color="#EDEDED"
              class="ba-0 rounded-l-sm rounded-r-0"
              filled
              rounded
              placeholder="Введите диапазон"
              @keyup.enter="getIdsFromRange"
            />
            <v-btn
              :disabled="!modalOptions.selectMultiple"
              height="auto"
              class="elevation-0 my-0 rounded-l-0"
              x-large
              dark
              min-width="50px"
              @click="getIdsFromRange"
            >
              <v-icon>mdi-plus</v-icon>
              <span class="d-none d-lg-block">Выбрать</span>
            </v-btn>
          </div>
          <div
            class="elements__table"
          >
            <v-virtual-scroll
              ref="wares"
              style="overflow-y: scroll"
              :items="items"
              :bench="10"
              :item-height="50"
              :height="height"
            >
              <template #default="{index,item}">
                <copy-field-element
                  :disabled="isElementDisabled(item.id)"
                  :item="item"
                  :index="index"
                  :selected="selectedWares.includes(item.id)"
                  @select="onItemSelect"
                />
              </template>
            </v-virtual-scroll>
          </div>
        </div>


        <div class="d-flex justify-end mt-4">
          <v-btn
            elevation="0"
            color="#EDEDED"
            class="mr-2"
            @click="unset({cancel:true})"
          >
            Отмена
          </v-btn>
          <v-btn
            id="applyButton"
            :disabled="isDisabled"
            color="#5CB7B1"
            elevation="0"
            :dark="!isDisabled"
            @click="submit"
          >
            Применить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import {isGoodsRangeValid, rangeToArray , isCorrectStartEnd, trimRange} from '@/helpers/inputs'
import CopyFieldElement from './copy-field-element.vue';
import moment from "moment"
import {getStoreDocTypeByUrl} from "@/helpers/common";
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import {waitAsyncDecision as waitAsyncDecisionAlias} from "@/helpers/async-component";
export default {
  components:{
    CopyFieldElement,
  },
  mixins:[closeOnEscapeMixin],
  props:{
    show:{
      type:Boolean,
      required:true
    },
    options:{
      type:Object,
      required: false,
      default:null
    }
  },
  data:() => ({
    module:null,
    loading:false,
    withWares:false,
    items:[],
    selectedWares:[],
    range:"",
    validRange:"",
    asyncDecision:{
      resolve:null,
      reject:null,
    },
    modalOptions:{
      selectMultiple:true
    }
  }),
  computed:{
    requests(){
      if(this.module){
        return this.$store.getters[`${this.module}/getRequests`]
      }
      return []
    },
    selected(){
      if(this.module){
        return this.$store.getters[`${this.module}/getSelected`]
      }
      return null
    },
    height(){
      return window.innerHeight - 500
    },
    isDisabled(){
      return !this.selectedWares.length
    },
    shipmentIndex(){
      if(this.module){
        return this.$store.getters[`${this.module}/getShipmentIndex`] ?? null
      }
      return null
    },
    updateInShipment(){
      if(this.selected){
        return 'ware_shipments' in this.selected
      }
      return false
    }
  },
  watch:{
    options:{
      handler(){
        Object.keys(this.modalOptions).forEach(key => {
          if(key in this.options){
            this.modalOptions[key] = this.options[key]
          }
        })
      },
      deep:true,
      immediate:true
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    withWares(nv){
      if(nv === true){
        this.selectedWares = this.items.map(i => i.id)
      }else{
        this.selectedWares = []
      }
    },
  },
  created() {
    this.setModule()
  },
  methods:{
    isElementDisabled(id){
      return !!(!this.modalOptions.selectMultiple && this.selectedWares.length && !this.selectedWares.includes(id))
    },
    waitAsyncDecisionAlias,
    waitAsyncDecision(){
      this.onModalOpen()
      return this.waitAsyncDecisionAlias()
    },
    prepareWares(wares){
      const preparedWares = wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          add_tn_ved,
          net_weight,
          gross_weight,
          invoice_cost,
          shipment_date: date,
          invoice_currency_letter,
          origin_country_letter,
          title: titleDefault = "",
        } = item
        const shipment_date = date ? moment(date).format('DD.MM.YYYY') : ''
        const title = titleDefault !== null && titleDefault.length > 120 ? titleDefault.slice(0, 130) + '...' : titleDefault
        const cost = invoice_cost && invoice_currency_letter ? `${invoice_cost} ${invoice_currency_letter}` : invoice_cost
        return {
          id,
          index:idx + 1,
          tn_ved,
          add_tn_ved,
          title,
          shipment_date,
          net_weight,
          gross_weight,
          invoice_cost,
          cost,
          invoice_currency_letter,
          origin_country_letter,
        }
      })
      return Promise.resolve(preparedWares)
    },
    setModule(){
      const [,module] = this.$route.path.split('/')
      this.module = getStoreDocTypeByUrl(module)
    },
    scrollToWare(index){
      if(index === null) return;
      const container = this.$refs.wares.$el
      container.scrollTop = index * 50
    },
    onModalOpen({index} = {}){
      Promise.all(this.requests)
        .then(this.loadWares)
        .then(this.prepareWares)
        .then(this.fillItemsWithWares)
        .then(this.showModalWindow)
        .then(() => this.$nextTick())
        .then(this.focusOnRangeInput)
        .then(() => this.scrollToWare(index))
        .catch(() => this.$error())
    },
    focusOnRangeInput(){
      this.$refs.rangeField.focus()
      return Promise.resolve()
    },
    showModalWindow(){
      this.show = true
      return Promise.resolve()
    },
    fillItemsWithWares(preparedWares){
      this.items = preparedWares
      return Promise.resolve()
    },
    loadWares(){
      const wares = this.shipmentIndex !== null
        ? this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
        : this.$store.getters[`${this.module}/getSelectedWares`]
      return Promise.resolve(wares)

    },
    getText(err){
      return typeof err === 'object' &&'response' in err ? err.response : (err ||"Ошибка" )
    },
    submit(){
      if(this.asyncDecision.resolve !== null) this.asyncDecision.resolve(this.selectedWares)
      this.close()
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.validRange = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.validRange
        });
      }
    },
    getIdsFromRange(){
      this.range = trimRange(this.range)
      if(!isCorrectStartEnd(this.range)){
        return this.$snackbar({text:"Неверный диапазон", color:'red',top:false, right: false})
      }
      const idxs = rangeToArray(this.range, true)
      const values = idxs.map(idx => {
        return this.items[idx]?.id
      })
      if(values.length > this.items.length){
        this.range = ""
        return this.$snackbar({
          text:'Неверно указан диапазон. Кол-во выбранных товаров превышает имеющееся кол-во.',
          color:'red',
          top:false, right: false,
          timeout:5000,
        })
      }
      this.selectedWares = values
      // фокус на кнопку при нажатии Enter
      this.$nextTick(() => { document.querySelector("#applyButton").focus() })
    },
    unset({cancel = false} = {}){
      this.withWares = false
      this.loading = false
      this.range = ""
      if(cancel){
        this.onSelectAll()
      }else{
        this.selectedWares = []
        this.items = []
      }
    },
    onSelectAll(){
      this.withWares ? this.selectedWares = this.items.map(i => i.id) : this.selectedWares = []
    },
    onItemSelect(id){
      if(this.selectedWares.includes(id)){
        this.selectedWares = this.selectedWares.filter(i => i !== id)
      }else{
        this.selectedWares = this.selectedWares.length && this.modalOptions.selectMultiple === false ? this.selectedWares : [...this.selectedWares, id]
      }
    },
    onCloseClick(){
      if(this.asyncDecision.reject !== null) this.asyncDecision.reject()
      this.close()
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
